/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import cn from 'classnames';
import { stringify } from 'query-string';
import { useHistory } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';
import { useQueryParams } from '../hooks/queryString';
import { useModal } from '../hooks/modal';
import CustomerCreate from './CustomerCreate';
import Button from './common/Button';
import FilterSelect from './common/FilterSelect';
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request';
import { BRANCH_LIST, CUSTOMER_EXPORT, EMPLOYEE_LIST, FILE_UPLOAD, GROUPS_LIST, SUBJECT_LIST } from '../urls';
import SearchInput2 from './common/SearchInput2';
import { PermissionDeniedModal } from './PermissionDeniedModal';
import { domain } from "../utils/request";
import { isEmpty } from "lodash";
import { useMessage } from "../hooks/message";

export default function CustomersActionsBar({ view, onViewChange, customers, statusList, searchParams, setSearchParams }) {
	const history = useHistory();
	const params = useQueryParams();
	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />, styles.modal);

	function filterByType(type) {
		history.push(`?${stringify({ ...params, type })}`);
	}

	const [showCreateModal, hideCreateModal] = useModal(
		<CustomerCreate
			onCancel={() => hideCreateModal()}
			onSuccess={() => {
				customers.reload();
				hideCreateModal();
			}}
		/>
	);

	const branch = useLoad({ url: BRANCH_LIST });
	const branchItems = branch.response ? branch.response : [];

	const group = useLoad({ url: GROUPS_LIST });
	const groupItems = group.response?.results || [];

	const teachers = useLoad({
		url: EMPLOYEE_LIST,
		params: { positions: 'teacher', size: 1000, isArchive: 'archive' }
	});
	const teachersOptions = teachers.response?.results || [];

	const subject = useLoad({ url: SUBJECT_LIST });
	const subjectList = subject.response?.results || [];

	const excel = useGetRequest({ url: CUSTOMER_EXPORT })
	const excelItem = excel.response ? excel.response.fileUrl || excel.response.file : null

	useEffect(() => {
		if (excelItem && !isEmpty(excelItem)) {
			const a = document.createElement('a')
			a.href = `${domain}${excelItem}`
			a.setAttribute('file', 'response')
			a.click()
		}
	}, [excelItem])

	const [showMessage] = useMessage()
	const { request: postExcel } = usePostRequest({ url: FILE_UPLOAD })
	const handleFileUploadOnChange = async (e) => {
		const file = e.target.files[0]
		const formData = new FormData()
		formData.append('file', file)
		const { success, error } = await postExcel({ data: formData })
		if (success) {
			customers.request()
			showMessage('Файл успешно отправлен', 'is-success')
		}
		if (error) {
			showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
		}
	}


	return (
		<main className={cn(css(styles.parent))}>
			<aside style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
				<hgroup>
					<div className='field has-addons are-small'>
						<p className='control'>
							<button className='button is-small' onClick={() => onViewChange('table')} disabled={view === 'table'} title='Отобразить в виде таблицы'>
								<i className={cn('icon ion-md-grid', css(styles.icon))} />
							</button>
						</p>

						<p className='control'>
							<button className='button is-small' onClick={() => onViewChange('board')} disabled={view === 'board'} title='Отобразить в виде колонок'>
								<i className={cn('icon ion-md-list', css(styles.icon))} />
							</button>
						</p>
					</div>
				</hgroup>

				{/* <div>
                <div className="field has-addons are-small">
                    <p className="control">
                        <button className="button is-small"
                            onClick={() => onViewChange('board')}
                            disabled={view === 'board'}
                            title="Отобразить в виде колонок">
                            <i className="icon ion-md-mail" />
                            <p>Сообщение</p>
                        </button>
                    </p>
                </div>
            </div> */}

				<hgroup>
					<div className='tags is-right'>
						<a onClick={() => filterByType('')} className={cn('tag', { 'light-info': params.type === '' })}>
							<i className='icon ion-ios-people' />
							Все клиенты
						</a>

						<a onClick={() => filterByType('active')} className={cn('tag', { 'light-primary': params.type === undefined || params.type === 'active' })}>
							<i className='icon ion-ios-flame' />
							Активные
						</a>

						<a onClick={() => filterByType('rejected')} className={cn('tag', { 'light-danger': params.type === 'rejected' })}>
							<i className='icon ion-ios-warning' />
							Отказавшиеся
						</a>

						<a onClick={() => filterByType('archived')} className={cn('tag', { 'light-gray': params.type === 'archived' })}>
							<i className='icon ion-ios-filing' />
							Архив
						</a>
					</div>
				</hgroup>

				<hgroup style={{
					display: 'flex',
					alignItems: 'center',
					gap: '1rem',
					flex: 'auto',
					flexWrap: 'wrap',
					justifyContent: 'flex-end'
				}}>

					<label style={{ position: 'relative' }}>
						<Button text='Импорт' className={cn('is-outlined is-info is-size-6')}
								icon='ion-md-cloud-download'/>
						<input
							value=""
							onChange={handleFileUploadOnChange}
							type="file"
							style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }}
						/>
					</label>

					<Button onClick={excel.request}
							text='Экспорт' className={cn('is-outlined is-info is-size-6')} icon='ion-md-cloud-upload'/>
					{view !== 'board' && <Button text='Добавить' onClick={showCreateModal}
												 className={cn('is-outlined is-info is-size-6')} icon='ion-md-add'/>}
				</hgroup>
			</aside>

			<div style={{
				display: 'grid',
				gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, auto))',
				gap: '1rem',
				width: '100%',
				marginBottom: '2rem'
			}}>
				<FilterSelect name={'subject'} options={subjectList} placeholder='Предмет'/>
				{view !== 'board' &&
					<FilterSelect name={'state'} optionLabelKey='title' options={statusList} placeholder='Состояние'/>}
				<FilterSelect name={'teacher'} options={teachersOptions} placeholder='Учитель'/>
				<SearchInput2 name='age' placeholder='Введите возраст' type='number' searchParams={searchParams}
							  setSearchParams={setSearchParams}/>
				{isSuperAdmin ? <FilterSelect name={'company'} options={branchItems} placeholder='Филиалы' /> : null}
				<FilterSelect name={'group'} options={groupItems} placeholder='Группы' />
			</div>
		</main>
	);
}

const styles = StyleSheet.create({
	parent: {},
	icon: { transform: 'rotate(90deg) scale(1.5)' },
	modal: { width: 1000 },
});
